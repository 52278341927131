import { PropsWithChildren, createContext, useContext } from 'react'
import { NavigationLinkGroups } from '.'

const GroupsContext = createContext<NavigationLinkGroups>([])

export const useGroupsContext = () => useContext(GroupsContext)
export function GroupsContextWrapper({
  groups,
  children
}: PropsWithChildren<{ groups: NavigationLinkGroups }>) {
  return (
    <GroupsContext.Provider value={groups}>{children}</GroupsContext.Provider>
  )
}
