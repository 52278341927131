import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import {
  EmployeesIcon,
  HomeIcon,
  MenusIcon,
  ReportsIcon
} from '@toasttab/buffet-pui-icons'
import {
  PageMissing404DesktopIllustration,
  PageMissing404MobileIllustration
} from '@toasttab/buffet-pui-illustrations'
import {
  ErrorContainer,
  ErrorHeading,
  ErrorSubheading,
  ErrorCallToActionSection,
  ErrorText
} from '../Error'
import { t, loadStrings, DefaultStrings } from '../defaultStrings'

export const externalLinks = {
  home: {
    href: '/restaurants/admin/home',
    icon: <HomeIcon accessibility='decorative' />,
    label: 'link-aria-label.home'
  },
  menus: {
    href: '/restaurants/admin/menus/homepage',
    icon: <MenusIcon accessibility='decorative' />,
    label: 'link-aria-label.menus'
  },
  employees: {
    href: '/restaurants/admin/employees/homepage',
    icon: <EmployeesIcon accessibility='decorative' />,
    label: 'link-aria-label.employees'
  },
  reports: {
    href: '/restaurants/admin/reports/homepage',
    icon: <ReportsIcon accessibility='decorative' />,
    label: 'link-aria-label.reports'
  }
}

export function ErrorPage404({
  testId = 'error-page-404',
  links = [
    externalLinks.home,
    externalLinks.menus,
    externalLinks.employees,
    externalLinks.reports
  ]
}) {
  loadStrings()
  return (
    <ErrorContainer>
      <div data-testid={testId}>
        <div
          className='hidden md:block visible mx-auto'
          style={{ maxWidth: 600 }}
        >
          <PageMissing404DesktopIllustration
            accessibility='semantic'
            title={t('404-error')}
          />
        </div>
        <div
          className='block md:hidden visible mx-auto'
          style={{ maxWidth: 250 }}
        >
          <PageMissing404MobileIllustration resizeToContainer />
        </div>
      </div>
      <div>
        <ErrorHeading>
          {t('page-missing-intro.sorry-this-dish-is-no')}
        </ErrorHeading>
        <ErrorSubheading>
          {t('page-missing-subheading.we-dont-have-the-ingredients')}
        </ErrorSubheading>
        <ErrorSubheading>
          {t('page-missing-suggestion.maybe-try-one-of-these')}
        </ErrorSubheading>
      </div>
      <ErrorCallToActionSection spacing='flex flex-col items-center space-y-4 md:space-y-0 md:space-x-4'>
        {links.map((link) => {
          const LinkIcon = React.cloneElement(link.icon, {
            ...link.icon.props,
            accessibility: 'decorative'
          })
          return (
            <Button
              key={`error-link-${link.label}`}
              testId={`${testId}-${link.label}`}
              variant='link'
              as='a'
              href={link.href}
              iconLeft={LinkIcon}
            >
              {t(link.label as keyof DefaultStrings)}
            </Button>
          )
        })}
      </ErrorCallToActionSection>
      <div className='space-y-2'>
        <ErrorText>{t('404-page-not-found')}</ErrorText>
      </div>
    </ErrorContainer>
  )
}
