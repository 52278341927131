import {
  CallSupportIcon,
  ThumbsUpIcon,
  ToastCentralIcon
} from '@toasttab/buffet-pui-icons'
import { BaseItem } from './type'

export const helpItems: BaseItem[] = [
  {
    label: 'Support',
    id: 'support',
    icon: CallSupportIcon,
    href: 'https://developers.toasttab.com/hc/en-us/requests/new',
    newTab: true
  },
  {
    label: 'Feedback',
    id: 'feedback',
    icon: ThumbsUpIcon,
    href: 'https://developers.toasttab.com/hc/en-us/community/topics/360001885991-Feature-Requests',
    newTab: true
  },
  {
    label: 'User guide',
    id: 'user-guide',
    icon: ToastCentralIcon,
    href: 'https://doc.toasttab.com/doc/devguide/apiDeveloperPortal.html',
    newTab: true
  }
]
