import { BanquetLoader } from 'banquet-runtime-modules'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import {
  useCheckDepotFeatureFlag,
  useDepotBanquetProps
} from '@toasttab/depot-banquet-props'
import { Navigation } from '@local/navigation'
import { useGetNavigationLinks } from './navigationLinks'
import { ErrorPage404 } from '@toasttab/buffet-pui-error-pages'
import { ChatHelpIcon, HomeIcon } from '@toasttab/buffet-pui-icons'

const externalLinkExtDev = [
  {
    href: 'https://developers.toasttab.com/hc/en-us/requests/new',
    icon: <ChatHelpIcon />,
    label: 'Open a ticket with Toast Developer Relations'
  }
]
const externalLinkUnexpectedUserType = [
  {
    href: 'https://toasttab.com/restaurants/admin',
    icon: <HomeIcon />,
    label: 'Home'
  }
]

export function App() {
  const props = useDepotBanquetProps() as any
  if (props.partner === undefined) {
    /* 
      Known issues:
        401: Unauthorized - for non ext dev users
        500: Internal Server Error - for deleted users 
    */
    const errorMessage = props?.message as string
    if (errorMessage.includes('401')) {
      return (
        <ErrorPage404
          testId='error-page-unexpected-user-type'
          links={externalLinkUnexpectedUserType}
        />
      )
    }
    return (
      <ErrorPage404
        testId='error-page-no-partner-association'
        links={externalLinkExtDev}
      />
    )
  }

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  )
}

export function AppRoutes() {
  const dpiEnabled = useCheckDepotFeatureFlag('partners-digi-spi-config')

  const navigationLinks = useGetNavigationLinks()

  return (
    <>
      <Navigation groups={navigationLinks} />
      <Routes>
        {/* 
          route order doesn't matter 
          https://reactrouter.com/en/main/components/routes
          <Routes> looks through all its child routes to find
          the best match and renders that branch of the UI
        */}
        <Route
          path='webhooks/*'
          element={<BanquetLoader name='tpc-manage-webhooks-spa' />}
        />
        {dpiEnabled && (
          <Route
            path='payment-methods/*'
            element={<BanquetLoader name='spi-partner-config-ui' />}
          />
        )}
        <Route //forwards routes to main-spa by default
          path='*'
          element={<BanquetLoader name='tpc-depot-main-spa' />}
        />
      </Routes>
    </>
  )
}
