import { ToastEnvironment } from '@toasttab/current-environment'
import { FeatureFlags } from '@toasttab/depot-banquet-props'
import { BaseItem } from './baseItems/type'

export function filterItems(
  featureFlags: FeatureFlags | undefined,
  currentEnv: ToastEnvironment,
  items: BaseItem[]
) {
  return items.filter((item) => {
    if (item.featureFlag) {
      return featureFlags?.find((flag) => flag.name === item.featureFlag)
        ?.enabled
    }
    if (item.hideInProd) {
      return currentEnv !== ToastEnvironment.PROD
    }
    return true
  })
}
