import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const PageMissing404DesktopIllustration = React.forwardRef(
  function PageMissing404DesktopIllustration(
    props: IllustrationProps,
    ref?: React.ForwardedRef<HTMLDivElement>
  ) {
    const resizeToContainer = props.resizeToContainer
    return (
      <IllustrationWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={resizeToContainer ? '100%' : '600'}
              height={resizeToContainer ? '100%' : '280'}
              viewBox='0 0 600 280'
              fill='none'
              className='inline-block'
            >
              <path
                d='M142.75 79.65H21.55M142.75 59.45H21.55M280.15 224.95v-38.4M320.55 226.35v-39.8'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M221.55 273.25l78.1-63.4 80.4 63.4'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
              />
              <path
                d='M230.15 189.25v-43.1M250.35 189.25v-43.1M240.25 147.45v-20.2M251.65 146.75h-22.6M360.55 147.45v-20.2M350.35 189.25v-43.1M370.65 187.85v-41.8M371.85 146.75h-22.6'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M50.95 19.05h-20M40.95 59.15v-18.5M50.95 30.05v-11M30.95 30.05v-11'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
              />
              <path
                d='M40.95 40.15c-5.5 0-10-4.3-10-9.5M40.95 40.15c5.5 0 10-4.3 10-9.5'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M91.45 19.05h-20M81.35 59.15v-18.5M91.45 30.05v-11M71.35 30.05v-11'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
              />
              <path
                d='M81.35 40.15c-5.5 0-10-4.3-10-9.5M81.35 40.15c5.5 0 10-4.3 10-9.5'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M131.85 19.05h-20M121.75 59.15v-18.5M131.85 30.05v-11M111.75 30.05v-11'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
              />
              <path
                d='M121.75 40.15c-5.5 0-10-4.3-10-9.5M121.75 40.15c5.5 0 10-4.3 10-9.5'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M269.95 6.35h-20M259.85 46.35v-18.5M269.95 17.35v-11M249.85 17.35v-11'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
              />
              <path
                d='M259.85 27.35c-5.5 0-10-4.3-10-9.5M259.85 27.35c5.5 0 10-4.3 10-9.5'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M310.35 6.35h-20M300.35 46.35v-18.5M310.35 17.35v-11M290.25 17.35v-11'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
              />
              <path
                d='M300.35 27.35c-5.5 0-10-4.3-10-9.5M300.35 27.35c5.5 0 10-4.3 10-9.5'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M527.95 19.05h-20M517.85 59.15v-18.5M527.95 30.05v-11M507.85 30.05v-11'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
              />
              <path
                d='M517.85 40.15c-5.5 0-10-4.3-10-9.5M517.85 40.15c5.5 0 10-4.3 10-9.5'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M350.75 6.35h-20M340.75 46.35v-18.5M350.75 17.35v-11M330.65 17.35v-11'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
              />
              <path
                d='M340.75 27.35c-5.5 0-10-4.3-10-9.5M340.75 27.35c5.5 0 10-4.3 10-9.5'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M568.35 19.05h-20M558.25 59.15v-18.5M568.35 30.05v-11M548.25 30.05v-11'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
              />
              <path
                d='M558.25 40.15c-5.5 0-10-4.3-10-9.5M558.25 40.15c5.5 0 10-4.3 10-9.5'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M142.75 79.65v-20.2'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='square'
              />
              <path
                d='M360.35 66.95h-121.3M578.55 79.65h-81.5M360.35 46.75h-121.3M578.55 59.45h-81.5'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M360.35 66.95v-20.2M239.05 66.95v-20.2M497.05 79.65v-20.2'
                stroke='#E5E5E5'
                strokeWidth={3}
                strokeLinecap='square'
              />
              <path
                d='M138.05 273.65v-22.9m-60.6 22.9v-22.9 22.9zM185.85 250.75H57.25v-103.3c16.7 0 29.6 13.6 29.6 30.3M117.85 208.05c-17.1 0-31-13.9-31-31M169.05 208.05c-17.1 0-31-13.9-31-31'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M138.05 177.05c0-16.4-12.8-29.6-29.4-29.6h-51.4M117.85 208.05c16.7 0 30.3 13.6 30.3 30.3v13.5M461.35 250.75v22.9m60.6-22.9v22.9-22.9zM512.55 177.05c0 17.1-13.9 31-31 31M461.35 177.05c0 17.1-13.9 31-31 31M542.15 147.45h-51.4c-16.6 0-29.4 13.3-29.4 29.6'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M512.55 177.75c0-16.7 12.9-30.3 29.6-30.3v103.3h-128.6'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M451.25 251.85v-13.5c0-16.7 13.6-30.3 30.3-30.3M167.75 208.05c17.1 0 31 16.7 31 33.7M400.15 241.75c0-17 13.9-33.7 31-33.7'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M203.45 187.85c6 0 10.8-4.1 10.8-9.1v-11.1'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='square'
              />
              <path d='M214.25 167.65h-20.2' stroke='silver' strokeWidth={3} />
              <path
                d='M164.45 187.85c-6 0-10.8-4.1-10.8-9.1v-11.1'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='square'
              />
              <path d='M194.05 167.65h-40.4' stroke='silver' strokeWidth={3} />
              <path
                d='M435.35 187.85c6 0 10.8-4.1 10.8-9.1v-11.1'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='square'
              />
              <path d='M446.15 167.65h-20.2' stroke='silver' strokeWidth={3} />
              <path
                d='M396.25 187.85c-6 0-10.8-4.1-10.8-9.1v-11.1'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='square'
              />
              <path d='M425.95 167.65h-40.4' stroke='silver' strokeWidth={3} />
              <path
                d='M267.75 176.45v12.8M331.85 175.45v13.7'
                stroke='#FF4C00'
                strokeWidth={3}
              />
              <path
                d='M271.25 128.65l66 18.8'
                stroke='#FF4C00'
                strokeWidth={3}
                strokeLinecap='round'
              />
              <path
                d='M315.65 98.75l-1.9 6.5'
                stroke='#FF4C00'
                strokeWidth={3}
              />
              <path
                d='M301.15 94.55l27.5 7.8'
                stroke='#FF4C00'
                strokeWidth={3}
                strokeLinecap='round'
              />
              <path
                d='M337.15 147.45c5.3-18.5-5.3-37.6-23.5-42.8-18.2-5.2-37.2 5.6-42.5 24'
                stroke='#FF4C00'
                strokeWidth={3}
              />
              <path
                d='M267.75 175.45h64.1'
                stroke='#FF4C00'
                strokeWidth={3}
                strokeLinecap='square'
              />
              <path
                d='M.15 273.65h599.7M299.75 273.65v-87.1'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M481.55 187.85h-363.7'
                stroke='#FF4C00'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          }
        </>
      </IllustrationWrapper>
    )
  }
)
