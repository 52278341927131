import {
  HeaderToggle,
  NavigationRailContainer,
  NavigationRailLayout
} from '@toasttab/buffet-pui-global-navigation'
import { MenuIcon } from '@toasttab/buffet-pui-icons'

import { Menu } from '@local/navigation'

interface CategoriesMenuToggleProps {
  onClick: Function
}
export function CategoriesMenuToggle({ onClick }: CategoriesMenuToggleProps) {
  return (
    <HeaderToggle
      label='Navigation Toggle'
      icon={MenuIcon}
      onClick={() => onClick()}
    />
  )
}

interface CategoriesPaneProps {
  onClose: Function
}
export function CategoriesPane({ onClose }: CategoriesPaneProps) {
  return (
    <NavigationRailLayout>
      <NavigationRailContainer>
        <Menu canExpand isOpen onClickItem={() => onClose()} />
      </NavigationRailContainer>
    </NavigationRailLayout>
  )
}
