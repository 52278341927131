import React from 'react'
import cx from 'classnames'

interface ErrorContainerProps {
  children: React.ReactNode
}

export function ErrorContainer({ children, ...props }: ErrorContainerProps) {
  return (
    <div className='mx-auto py-6 md:py-16 px-4'>
      <div className='space-y-6 md:space-y-10' {...props}>
        {children}
      </div>
    </div>
  )
}

export function ErrorHeading({ ...props }) {
  return (
    // eslint-disable-next-line jsx-a11y/heading-has-content
    <h1
      className='type-headline-3 md:type-headline-2 text-center mb-6 text-default'
      {...props}
    />
  )
}

export function ErrorSubheading({ ...props }) {
  return <p className='text-center type-large text-secondary mb-2' {...props} />
}

export function ErrorCallToActionSection({
  spacing = 'w-40 space-y-6 md:space-y-0 md:space-x-12',
  ...props
}) {
  return (
    <div
      className={cx(
        'md:flex-row mx-auto md:w-auto md:flex md:justify-center md:pb-6',
        spacing
      )}
      {...props}
    />
  )
}

export function ErrorText({ ...props }) {
  return (
    <p
      className='first:pt-10 text-center type-caption uppercase text-secondary'
      {...props}
    />
  )
}
