import {
  DesktopNavigationLayout,
  HeaderContainer,
  NavigationRailContainer,
  NavigationRailLayout,
  useNavigationShell
} from '@toasttab/buffet-pui-global-navigation'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'

import { DesktopHeader, MobileHeader, ProfileLinkItem } from '@local/header'

import { NavigationLinkGroups } from '.'
import { GroupsContextWrapper } from './GroupsContext'
import { Menu } from './Menu'

export interface NavigationProps {
  groups: NavigationLinkGroups
  profileLinks?: Array<ProfileLinkItem>
  environment?: string
}

export function Navigation({
  groups,
  profileLinks = [],
  environment = ''
}: NavigationProps) {
  const size = useScreenSize()
  const { width, disclosure, ...events } = useNavigationShell()

  return (
    <GroupsContextWrapper groups={groups}>
      <DesktopNavigationLayout className='z-40'>
        <div>
          {size >= ScreenSize.MD && (
            <NavigationRailLayout {...events}>
              <NavigationRailContainer style={{ width }}>
                <Menu canExpand isOpen={disclosure.isOpen} />
              </NavigationRailContainer>
            </NavigationRailLayout>
          )}

          <HeaderContainer>
            {size < ScreenSize.MD && <MobileHeader />}
            {size >= ScreenSize.MD && (
              <DesktopHeader
                environment={environment}
                isOpen={disclosure.isOpen}
                onToggle={disclosure.onToggle}
                profileLinks={profileLinks}
              />
            )}
          </HeaderContainer>
        </div>
      </DesktopNavigationLayout>
    </GroupsContextWrapper>
  )
}
