import { convertToDashCase } from '@local/navigation/utils'
import { Button } from '@toasttab/buffet-pui-buttons'
import { IconProps } from '@toasttab/buffet-pui-icons'
import { ForwardRefExoticComponent, RefAttributes } from 'react'

export type HeaderLinkProps = {
  text: string
  Icon: ForwardRefExoticComponent<IconProps & RefAttributes<HTMLElement>>
  href: string
}

export default function HeaderLink({ text, href, Icon }: HeaderLinkProps) {
  return (
    <Button
      as='a'
      variant={Button.Variant?.link}
      className='type-subhead'
      iconLeft={<Icon className='text-gray-125' accessibility='decorative' />}
      href={href}
      target='_blank'
      data-toast-track-id={`tpc-depot-header-${convertToDashCase(text)}`}
    >
      <span className='text-gray-125 font-normal'>{text}</span>
    </Button>
  )
}
