import { NavigationLinkGroups, NavigationLinkItem } from '@local/navigation'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { useMemo } from 'react'
import { helpItems } from './baseItems/helpItems'
import { mainItems } from './baseItems/mainItems'
import { filterItems } from './filterMainItems'

export const useGetNavigationLinks = (): NavigationLinkGroups => {
  const { partner } = useDepotBanquetProps()
  const currentEnv = getCurrentEnvironment()

  const filteredMainItems: NavigationLinkItem[] = useMemo(
    () => filterItems(partner.featureFlags, currentEnv, mainItems),
    [currentEnv, partner.featureFlags]
  )

  return [
    {
      id: 'main',
      items: filteredMainItems
    },
    {
      id: 'help',
      items: helpItems
    }
  ]
}
