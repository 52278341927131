export const deleteNonButtonProps = (buttonProps: any) => {
  // poperties of Link from react-router-dom that do not exist on ButtonHTMLAttributes
  delete buttonProps.reloadDocument
  delete buttonProps.replace
  delete buttonProps.state
  delete buttonProps.to

  // AnchorHTMLAttributes that do not exist on ButtonHTMLAttributes
  delete buttonProps.target
  delete buttonProps.download
  delete buttonProps.href
  delete buttonProps.hrefLang
  delete buttonProps.media
  delete buttonProps.ping
  delete buttonProps.rel
  delete buttonProps.target
  delete buttonProps.referrerPolicy
}
