import {
  NavigationRailToggleButton,
  HeaderDivider,
  DesktopContainer,
  HeaderContents,
  ToastDesktopLogo
} from '@toasttab/buffet-pui-global-navigation'
import { LaptopIcon } from '@toasttab/buffet-pui-icons'

import { ProfileLinkItem } from '../..'
import { ProfileDropdown } from '../ProfileDropdown'
import { EnvironmentHeader } from '../EnvironmentHeader'
import { headerLinks } from '../../headerLinks'
import HeaderLink from './HeaderLink'

export interface DesktopHeaderProps {
  environment?: string
  isOpen: boolean
  onToggle: Function
  profileLinks?: Array<ProfileLinkItem>
}

export function DesktopHeader({ isOpen, onToggle }: DesktopHeaderProps) {
  return (
    <DesktopContainer data-testid='header-desktop'>
      <ToastDesktopLogo isOpen={isOpen}></ToastDesktopLogo>
      <HeaderContents>
        <NavigationRailToggleButton
          isOpen={isOpen}
          onClick={onToggle}
          data-testid='navigationRailToggle'
        />
        <LaptopIcon
          size='md'
          className='text-brand-50'
          accessibility='decorative'
        />
        <EnvironmentHeader />
        <div className='flex-1' />
        {headerLinks.map((props) => (
          <HeaderLink key={props.text} {...props} />
        ))}
        <HeaderDivider />
        <ProfileDropdown />
      </HeaderContents>
    </DesktopContainer>
  )
}
