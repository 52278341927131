import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const Error500MobileIllustration = React.forwardRef(
  function Error500MobileIllustration(
    props: IllustrationProps,
    ref?: React.ForwardedRef<HTMLDivElement>
  ) {
    const resizeToContainer = props.resizeToContainer
    return (
      <IllustrationWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={resizeToContainer ? '100%' : '300'}
              height={resizeToContainer ? '100%' : '180'}
              viewBox='0 0 300 180'
              fill='none'
              className='inline-block'
            >
              <path
                d='M247.3 128.8c0 4 2.7 7.6 6.7 7.6M222.1 147.3c0 4 2.7 6.7 6.7 6.7h1.3M222.1 148.1c0-4-2.7-7.6-6.7-7.6M215.4 140.6c-3.6 0-5.9-3.1-5.9-6.7v-4.6M254 136.4c4 0 5.9-3.4 5.9-7.2v-2'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M229.7 154c3.6 0 5-2.4 5-6v-19.6M176.3 115.9h20.2M196.1 89.8h-32.8c-17.2 0-24.4-9.7-24.4-24.8V50.8M118.6 39h-5.2C96.2 39 89 29.3 89 14.2V1.6M196.1 115.9c6.7 0 12.6-6.3 12.6-13.4M126.8 1.6c0 6.7 6.3 12.6 13.4 12.6h16M254.1 127.6c12.5 0 23.5-11.8 23.5-25.2'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M252.4 64.6c13.4 0 25.2 11 25.2 23.5v16M189.4 53.7v-16c0-12.5-11.8-23.5-25.2-23.5h-13.8M189.4 37.7v13.9c0 8.5 3.7 13 12.6 13h51.7M208.7 102.4c0-6.7-5.9-12.6-12.6-12.6M138.9 51.6c0-6.7-5.9-12.6-12.6-12.6h-7.8'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M20.3 140.6v-11.4'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M260.9 165.7v-12.8c0-7.3 5.4-12.4 12.5-12.4h14.9M260.9 171.6c0 3.6-3.1 6.7-6.7 6.7M197.9 171.6c0 3.6 3.1 6.7 6.7 6.7M260.9 127.1v45.1M197.9 127.1v45.1M266.1 127.9h22.1M266.1 127.9h-69.5'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M45.5 143.1c1.436 0 2.6-1.119 2.6-2.5s-1.164-2.5-2.6-2.5c-1.436 0-2.6 1.119-2.6 2.5s1.164 2.5 2.6 2.5zM58.1 143.1c1.436 0 2.6-1.119 2.6-2.5s-1.164-2.5-2.6-2.5c-1.436 0-2.6 1.119-2.6 2.5s1.164 2.5 2.6 2.5zM70.7 143.1c1.436 0 2.6-1.119 2.6-2.5s-1.164-2.5-2.6-2.5c-1.436 0-2.6 1.119-2.6 2.5s1.164 2.5 2.6 2.5z'
                fill='silver'
              />
              <path
                d='M20.3 128.8c0-7 5.7-13.4 12.8-13.4h50.2M33.4 153.2c-7.1 0-13.2-5.7-13.2-12.7M32.9 178.4c-7.1 0-12.6-5.8-12.6-12.6M83.3 115.3c7 0 12.6 6.5 12.6 13.4M83.3 153.1c7 0 12.6-5.6 12.6-12.6M83.3 178.4c7 0 12.6-5.6 12.6-12.6'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M18.6 153.1h79'
                stroke='silver'
                strokeWidth={3}
                strokeLinejoin='round'
              />
              <path
                d='M20.3 165.7v-10.9M95.9 140.2v-11.4M95.9 166.9v-12.1'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M95.1 139.7c7.1 0 12.6 5 12.6 12.4v12.8'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M20.3 128.8h75.6'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M109.3 127.9c7.2 0 10.1 5.2 10.1 12.3V159M119.4 159c0 4 3.5 7.6 7.6 7.6M148 147.3c0 4 3.5 7.6 7.6 7.6M148 148.1c0-4-3.5-7.6-7.6-7.6M140.4 140.5c-3.6 0-6.7 3.1-6.7 6.7M127 166.6c3.6 0 6.7-3.2 6.7-6.9v-13.3M155.6 154.8c3.6 0 6.7-3.2 6.7-6.9v-13.3M107.7 127.1c0-7.2 4.9-12.6 12.1-12.6h50.9M183.3 127.1c0-7.2-5.1-12.6-12.6-12.6M107.7 127.9v44M145.5 103.6v9.2M132.9 101.9h25.2M183.3 128.8v43.1M183.3 153.1c0-7.5 5.8-12.6 12.6-12.6h.8'
                stroke='#FF4C00'
                strokeWidth={3}
              />
              <path
                d='M97.6 128.2h99.1'
                stroke='#FF4C00'
                strokeWidth={3}
                strokeLinejoin='round'
              />
              <path
                d='M107.7 171.6c0 3.6 3.1 6.7 6.7 6.7M183.3 171.6c0 3.6-3.1 6.7-6.7 6.7'
                stroke='#FF4C00'
                strokeWidth={3}
              />
              <path
                d='M0 178.4h300'
                stroke='#FF4C00'
                strokeWidth={3}
                strokeLinejoin='round'
              />
            </svg>
          }
        </>
      </IllustrationWrapper>
    )
  }
)
