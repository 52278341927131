import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const MenusIcon = React.forwardRef(function MenusIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M5.95 12.05h-2.9m2.9-6h-2.9m2.9 12h-2.9m14.6-11.1v3.3c0 .6-.7 1.1-1.5 1.1s-1.5-.5-1.5-1.1v-3.3m1.5 4.5v5.6m-5.9-5.6v5.6m8.8 5H6.45c-1.1 0-2-.9-2-2v-16c0-1.1.9-2 2-2h12.5c1.1 0 2 .9 2 2v16c.1 1.1-.8 2-1.9 2zm-7.1-12.9c0 1.215-.761 2.2-1.7 2.2-.94 0-1.7-.985-1.7-2.2 0-1.215.76-2.2 1.7-2.2.939 0 1.7.985 1.7 2.2z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
