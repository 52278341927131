export default {
  '403-access-forbidden': {
    message: '403: Access Forbidden',
    description: 'Error message for 403 Forbidden status'
  },
  '404-page-not-found': {
    message: '404: Page not found',
    description: 'Error message for 404 Not Found status'
  },
  'someone-needs-to-unlock-this': {
    message: 'Someone needs to unlock this for you.',
    description: 'Message indicating that someone needs to unlock something'
  },
  'please-contact-your-restaurant-administrator': {
    message:
      'Please contact your restaurant administrator. They know where the spare key is hidden.',
    description:
      'Message instructing the user to contact the restaurant administrator'
  },
  'are-you-the-administrator-please': {
    message:
      'Are you the administrator? Please contact <Link>Customer Care</Link> for help.',
    description:
      'Question asking if the user is the administrator and suggesting to contact Customer Care'
  },
  'button.go-back': {
    message: 'Go back',
    description: 'Label for a "Go back" button'
  },
  '404-error': {
    message: '404 Error',
    description: 'Error message for 404 Not Found status'
  },
  'page-missing-intro.sorry-this-dish-is-no': {
    message: 'Sorry! This dish is no longer on the menu.',
    description: 'Introduction indicating that page being accessed is missing'
  },
  'page-missing-subheading.we-dont-have-the-ingredients': {
    message: 'We don’t have the ingredients to make this anymore.',
    description:
      'Subheading message indicating that the page being accessed is missing'
  },
  'page-missing-suggestion.maybe-try-one-of-these': {
    message: 'Maybe try one of these instead?',
    description: 'A suggestion to try another page instead of the missing one'
  },
  'link-aria-label.home': {
    message: 'Home',
    description: 'Accessible label for a link to the home page'
  },
  'link-aria-label.menus': {
    message: 'Menus',
    description: 'Accessible label for a link to the menus page'
  },
  'link-aria-label.employees': {
    message: 'Employees',
    description: 'Accessible label for a link to the employees page'
  },
  'link-aria-label.reports': {
    message: 'Reports',
    description: 'Accessible label for a link to the reports page'
  },
  'title.oops-we-have-some-cleaning': {
    message: 'Oops! We have some cleaning up to do.',
    description:
      'Title indicating that something went wrong, with a cleaning theme'
  },
  'subheading.not-to-worry-our-team': {
    message:
      'Not to worry. Our team is aware of the mess and they’re busy scrubbing pots and pans.',
    description:
      'Message reassuring the user that the team is aware of the issue'
  },
  'subheading-action.please-try-reloading-the-page': {
    message: 'Please try reloading the page.',
    description: 'Instruction asking the user to try reloading the page'
  },
  '500-error': {
    message: '500 Error',
    description: 'Error message for 500 Internal Server Error status'
  },
  'button.toast-system-status': {
    message: 'Toast system status',
    description: 'Label for a button to check the system status'
  },
  'button.reload': {
    message: 'Reload',
    description: 'Label for a button to reload the page'
  }
}
