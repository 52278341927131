import { JSX, useRef } from 'react'
import { useMatch } from 'react-router-dom'

import { LinkItem } from '@toasttab/buffet-pui-global-navigation'

import { NavigationLinkItem } from '.'
import { convertToDashCase } from './utils'

export interface MenuItemProps {
  item: NavigationLinkItem
  onClick?: (item: NavigationLinkItem) => void
}

export function MenuItem({
  item,
  onClick = () => {}
}: MenuItemProps): JSX.Element {
  const match = useMatch(`${item.to}/*` ?? item.href)
  const linkRef = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={linkRef}
      onClick={() => onClick(item)}
      data-testid={`${item.id}-link`}
      data-toast-track-id={`tpc-depot-nav-${convertToDashCase(item.label)}`}
    >
      <LinkItem
        isActive={!!match}
        icon={item.icon}
        to={item.to}
        href={item.href}
        openNewTab={item.newTab ?? false}
      >
        {item.label}
      </LinkItem>
    </div>
  )
}
