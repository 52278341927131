import { CommunityIcon, SignIcon, StatusIcon } from '@toasttab/buffet-pui-icons'
import { HeaderLinkProps } from './desktop/DesktopHeader/HeaderLink'

export const headerLinks: Readonly<HeaderLinkProps[]> = Object.freeze([
  {
    text: 'API Status',
    Icon: StatusIcon,
    href: 'https://status-dev.toasttab.com/'
  },
  {
    text: 'Documentation',
    Icon: SignIcon,
    href: 'https://doc.toasttab.com/'
  },
  {
    text: 'Community',
    Icon: CommunityIcon,
    href: 'https://toastintegrations.zendesk.com/hc/en-us/community/topics'
  }
])
