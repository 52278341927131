/**
 * Use for raw javascript code only
 */
export const VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
  destructive: 'destructive',
  'destructive-secondary': 'destructive-secondary',
  link: 'link',
  'text-link': 'text-link',
  'destructive-link': 'destructive-link',
  'destructive-text-link': 'destructive-text-link'
} as const

/**
 * Use for raw javascript code only
 */
export const SIZES = {
  base: 'base', // deprecated in favor of 'lg'
  lg: 'lg',
  sm: 'sm',
  auto: 'auto'
} as const
