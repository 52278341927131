import * as React from 'react'
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box'
import { useUniqueId } from '@toasttab/buffet-utils'
import { VARIANTS, SIZES } from './constants'
import {
  ButtonContent,
  ButtonCommonProps,
  useButtonStyles
} from '../ButtonContent'
import { deleteNonButtonProps } from './deleteNonButtonProps'

const defaultElement = 'button'

export type ButtonProps<
  TElementType extends React.ElementType = typeof defaultElement
> = PolymorphicComponentProps<TElementType, ButtonCommonProps>

export const Button: (<
  TElementType extends React.ElementType = typeof defaultElement
>(
  props: ButtonProps<TElementType>
) => React.ReactElement | null) & {
  displayName?: string
  Variant?: typeof VARIANTS
  Size?: typeof SIZES
} = React.forwardRef(
  <TElementType extends React.ElementType = typeof defaultElement>(
    {
      variant = 'primary',
      iconRight = null,
      iconLeft = null,
      size = 'auto',
      testId,
      className,
      children,
      as: asProp,
      ...buttonProps
    }: ButtonProps<TElementType>,
    ref: typeof buttonProps.ref
  ) => {
    testId = useUniqueId(testId, 'buttons-')
    // eslint-disable-next-line
    const classes = useButtonStyles({
      size,
      hasRightIcon: !!iconRight,
      hasLeftIcon: !!iconLeft,
      variant,
      className,
      disabled: buttonProps.disabled
    })

    const as =
      asProp === undefined || buttonProps.disabled ? defaultElement : asProp

    if (buttonProps.disabled && asProp !== undefined && asProp !== 'button') {
      // Only do this weird mutation thing in this edge case
      // which we explicitly code for and support
      deleteNonButtonProps(buttonProps)
    }

    // Ensure that the appropriate element-specific default props get added
    const defaultProps =
      as === 'button'
        ? {
            type: 'button'
          }
        : {}
    return (
      <Box
        as={as}
        ref={ref}
        data-testid={testId}
        {...defaultProps}
        // The cast is marginally better than a ts-ignore,
        // and sort of reasonable given we understand
        // the ButtonProps type.
        {...(buttonProps as ButtonProps<TElementType>)}
        className={classes}
      >
        {/* eslint-disable-next-line */}
        <ButtonContent iconRight={iconRight} iconLeft={iconLeft}>
          {children}
        </ButtonContent>
      </Box>
    )
  }
)

Button.displayName = 'Button'
Button.Variant = Object.freeze(VARIANTS)
Button.Size = Object.freeze(SIZES)
