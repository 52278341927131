import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const Error500DesktopIllustration = React.forwardRef(
  function Error500DesktopIllustration(
    props: IllustrationProps,
    ref?: React.ForwardedRef<HTMLDivElement>
  ) {
    const resizeToContainer = props.resizeToContainer
    return (
      <IllustrationWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={resizeToContainer ? '100%' : '600'}
              height={resizeToContainer ? '100%' : '280'}
              viewBox='0 0 600 280'
              fill='none'
              className='inline-block'
            >
              <path
                d='M475.4 143.55H472c-3 0-5.7 2.7-5.7 5.9v77.2M114.3 91.95c6 1.3 9.5 6.6 8.1 12.8m-7.1-12.5c-6-1.3-11.4 1.9-12.8 8.1M125.2 138.35c6.2 1.4 11.4-2.1 12.8-8.1l-19.2-4.3'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M125.9 138.45c-6.2-1.4-9.5-6.8-8.1-12.8l4.7-20.9M53.4 98.35c6 1.3 9.5 6.6 8.1 12.8m-7.1-12.6c-6-1.3-11.4 1.9-12.8 8.1M64.3 144.65c6.2 1.4 11.4-2.1 12.8-8.1l-19.2-4.3'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M65 144.85c-6.2-1.4-9.5-6.8-8.1-12.8l4.7-20.9M44.6 210.35c3.3-5.2 9.4-6.8 14.8-3.4m-14.2 2.5c-3.3 5.2-2 11.4 3.4 14.8M99.7 220.45c3.4-5.4 1.8-11.5-3.4-14.8l-10.4 16.6'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M100.1 219.85c-3.4 5.4-9.6 6.7-14.8 3.4l-27.5-17.2M475.4 147.95h100.4m-99.3 10.8h99.3-99.3zM576.1 60.55h-153v10.7h153M26 108.65l127.5-14.1m-127.9 24.8l129.1-14.1-129.1 14.1zM153.7 93.05l1.5 13.5'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M180.2 237.55v10M60.4 237.55v10M300 237.55v10'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='round'
              />
              <path
                d='M60.4 255.25v18.5M180.2 255.25v18.5M300 255.25v18.5'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M419.8 237.55v10'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='round'
              />
              <path d='M419.8 255.25v18.5' stroke='#E5E5E5' strokeWidth={3} />
              <path
                d='M539.6 237.55v10'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='round'
              />
              <path
                d='M539.6 255.25v18.5M476 146.55v13.6'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path d='M365.4 226.55h43.4' stroke='silver' strokeWidth={3} />
              <path
                d='M527.8 60.55v-32.2l-10.7-10.7-10.7 10.7v32.1'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M549.2 60.55v-32.2l-10.7-10.7h-21.4M549.2 28.35h-42.1M549.2 39.15h-42.1M538.5 18.05V6.95M517.1 18.05V6.95M538.1 8.45H517M457.1 28.35c3.7 0 6.4 2.6 6.4 6.4l-6.4 20.3M462.8 60.55c-3.3 0-5.7-2.3-5.7-5.7M490 60.55c3.3 0 5.7-2.3 5.7-5.7'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M495.7 55.85v-21.7c0-3.3-2.3-5.7-5.7-5.7h-36.8M496.4 34.85c3.4 0 5.7 2.4 5.7 5.7v7.9c0 3.3-2.3 5.7-5.7 5.7'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path d='M.1 237.25h471.5' stroke='silver' strokeWidth={3} />
              <path
                d='M321.4 150.15h-27.8c-14.6 0-20.7-8.2-20.7-21.1v-12.1M255.6 106.25h-4.4c-14.6 0-20.7-8.2-20.7-21.1v-12.1M321.4 172.25c5.7 0 10.7-5.3 10.7-11.4M262.6 76.75c0 5.7 5.3 10.7 11.4 10.7h13.5M230.5 73.05c0-5.7-5.3-10.7-11.4-10.7h-20M370.7 183.85c10.6 0 20-10 20-21.4'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M369.2 130.35c11.4 0 21.4 9.3 21.4 20v13.6M315.7 121.05v-13.6c0-10.6-10-20-21.4-20h-11.8M262.6 78.25v-13.6c0-10.6-10-20-21.4-20H198M315.7 107.45v11.8c0 7.2 3.1 11.1 10.7 11.1h43.9M332.1 160.85c0-5.7-5-10.7-10.7-10.7M272.9 116.95c0-5.7-5-10.7-10.7-10.7h-6.6M187.6 33.95c0 5.7 5.3 10.7 11.4 10.7M176.9 41.65c0 11 10.3 20.7 22.1 20.7M176.9 41.65v-22.9c0-4.6 1.4-8.9 3.9-12.5M187.6 33.95c0-6.1 3.2-10.7 10.7-10.7h54.6M251.9 23.25c13.1 0 19.7-7.1 21.1-17'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M215.2 196.65a2.2 2.2 0 100-4.4 2.2 2.2 0 000 4.4zM225.9 196.65a2.2 2.2 0 100-4.4 2.2 2.2 0 000 4.4zM236.6 196.65a2.2 2.2 0 100-4.4 2.2 2.2 0 000 4.4z'
                fill='silver'
              />
              <path
                d='M193.4 184.35c0-5.9 4.9-11.4 10.9-11.4h42.6M204.6 205.15c-6 0-11.2-4.8-11.2-10.8M204.4 226.55c-6 0-10.7-4.9-10.7-10.7M247.2 172.95c5.9 0 10.7 5.5 10.7 11.4M247.2 205.05c5.9 0 10.7-4.8 10.7-10.7M247.2 226.55c5.9 0 10.7-4.8 10.7-10.7'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M192.3 205.05h67.1'
                stroke='silver'
                strokeWidth={3}
                strokeLinejoin='round'
              />
              <path
                d='M193.4 194.35v-9.7M193.7 215.85v-9.3M257.9 194.15v-9.7M257.9 216.75v-10.3'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M269.4 183.85c6.2 0 8.6 4.4 8.6 10.4v16M277.9 210.25c0 3.4 3 6.4 6.4 6.4M302.2 200.35c0 3.4 3 6.4 6.4 6.4M302.2 201.05c0-3.4-3-6.4-6.4-6.4M295.8 194.55c-3 0-5.7 2.7-5.7 5.7M284.4 216.75c3 0 5.7-2.7 5.7-5.9v-11.3M308.6 206.75c3 0 5.7-2.7 5.7-5.9v-11.3'
                stroke='#FF4C00'
                strokeWidth={3}
              />
              <path
                d='M257.2 193.65c6 0 10.7 4.3 10.7 10.5v10.9'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M267.9 182.95c0-6.1 4.2-10.7 10.3-10.7h43.3M332.2 182.95c0-6.1-4.4-10.7-10.7-10.7M267.9 183.65v37.4'
                stroke='#FF4C00'
                strokeWidth={3}
              />
              <path
                d='M332.2 205.05c0-6.4 4.9-10.7 10.7-10.7h.7'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M300.1 162.95v7.9M332.2 184.35v36.6'
                stroke='#FF4C00'
                strokeWidth={3}
              />
              <path
                d='M418.5 193.65c-5.8 0-10.7 4.4-10.7 10.7M343.6 220.75c0 3 2.7 5.7 5.7 5.7'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M397.1 185.35c0 3.4 2.3 6.4 5.7 6.4M375.7 199.35c0 3.4 2.3 5.7 5.7 5.7h1.1M375.7 200.05c0-3.4-2.3-6.4-5.7-6.4M370 193.65c-3 0-5-2.7-5-5.7v-3.9M355 183.65v1.6c0 3.1-1.8 5.9-5.7 5.9-3.4 0-5.7-3-5.7-6.4M402.8 191.75c3.4 0 5-2.9 5-6.1v-1.7'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path d='M407.8 184.35v36.6' stroke='silver' strokeWidth={3} />
              <path
                d='M382.1 205.05c3 0 4.3-2 4.3-5.1v-16.6'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path
                d='M407.8 220.75c0 3-2.7 5.7-5.7 5.7M343.6 184.35v37.4M424.6 183.85h-82.5'
                stroke='silver'
                strokeWidth={3}
              />
              <path
                d='M193.7 184.35h64.2'
                stroke='silver'
                strokeWidth={3}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M259.4 183.85h82.7'
                stroke='#FF4C00'
                strokeWidth={3}
                strokeLinejoin='round'
              />
              <path
                d='M267.9 220.75c0 3 2.7 5.7 5.7 5.7M332.2 220.75c0 3-2.7 5.7-5.7 5.7M280 161.55h21.4M.1 226.55h471.5'
                stroke='#FF4C00'
                strokeWidth={3}
              />
              <path d='M482.2 237.25h117.6' stroke='silver' strokeWidth={3} />
              <path d='M481.1 226.55h118.8' stroke='#FF4C00' strokeWidth={3} />
              <path
                d='M475.4 143.55v83M535.3 126.55h-54.2l-10.7 10.7 10.7 10.7h54.2v-42.8h-54.2l-10.7 10.7v21.4M481 105.15v42.1M491.7 105.15v42.1M470.7 115.85h-11.1M470.7 137.25h-11.1M461.1 116.25v21.1M471.6 224.95v48.8M481.1 224.95v48.8M471.7 225.85h9.4'
                stroke='#E5E5E5'
                strokeWidth={3}
              />
              <path d='M265.1 226.55h-52.8' stroke='#FF4C00' strokeWidth={3} />
            </svg>
          }
        </>
      </IllustrationWrapper>
    )
  }
)
