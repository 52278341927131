import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const ReportsIcon = React.forwardRef(function ReportsIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M6.05 11.9a.75.75 0 000 1.5v-1.5zm1.6.75v.75a.75.75 0 00.688-.451l-.688-.299zm2-4.6l.688.299a.758.758 0 00.023-.062L9.65 8.05zm.8 0l.697-.277a.746.746 0 00-.073-.139l-.624.416zm3.1 7.8l.711-.237a.787.787 0 00-.014-.04l-.697.277zm.8.1l.624.416a.785.785 0 00.017-.027l-.641-.389zm2-3.3v-.75a.75.75 0 00-.642.361l.642.389zm1.7.75a.75.75 0 000-1.5v1.5zm-12 0h1.6v-1.5h-1.6v1.5zm2.288-.451l2-4.6-1.376-.598-2 4.6 1.376.598zm2.023-4.662a.445.445 0 01-.193.241.31.31 0 01-.156.047.223.223 0 01-.186-.109l1.248-.832a1.278 1.278 0 00-1.062-.559c-.36 0-.887.178-1.074.738l1.423.474zm-.608.04l3.1 7.8 1.394-.554-3.1-7.8-1.394.554zm3.085 7.76c.163.49.604.724.96.78.374.057.875-.05 1.176-.501l-1.248-.832a.32.32 0 01.3-.15c.03.004.066.016.106.045.04.03.098.089.13.184l-1.424.474zm2.153.252l2-3.3-1.283-.778-2 3.3 1.283.778zM16.35 13.4h1.7v-1.5h-1.7v1.5zm2.7 6.9h-14v1.5h14v-1.5zm-14 0c-.686 0-1.25-.564-1.25-1.25H2.3a2.756 2.756 0 002.75 2.75v-1.5zM3.8 19.05v-14H2.3v14h1.5zm0-14c0-.686.564-1.25 1.25-1.25V2.3A2.756 2.756 0 002.3 5.05h1.5zM5.05 3.8h14V2.3h-14v1.5zm14 0c.686 0 1.25.564 1.25 1.25h1.5a2.756 2.756 0 00-2.75-2.75v1.5zm1.25 1.25v14h1.5v-14h-1.5zm0 14c0 .686-.564 1.25-1.25 1.25v1.5a2.756 2.756 0 002.75-2.75h-1.5z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
