import {
  BrandedMobileBackground,
  HomeLink,
  MobileDivider
} from '@toasttab/buffet-pui-global-navigation'
import { useState } from 'react'

import { CategoriesMenuToggle, CategoriesPane } from './MobileCategoriesMenu'
import { HeaderOverlayWithIcons } from './MobileHeaderOverlay'

export function MobileHeader() {
  const [showOverlay, setShowOverlay] = useState(false)
  const closeOverlay = () => {
    setShowOverlay(false)
    setShowCategoriesOverlay(false)
  }

  //there may be more than one overlay in the future, that's why we have 2 separate states
  const [showCategoriesOverlay, setShowCategoriesOverlay] = useState(false)
  const openCategoriesOverlay = () => {
    setShowOverlay(true)
    setShowCategoriesOverlay(true)
  }

  return (
    <>
      <header data-testid='header-mobile'>
        <BrandedMobileBackground>
          <div className='flex'>
            <HomeLink />
            <MobileDivider />
            <CategoriesMenuToggle onClick={openCategoriesOverlay} />
          </div>
          <div className='flex-1' />
        </BrandedMobileBackground>
      </header>
      <HeaderOverlayWithIcons onClose={closeOverlay} isOpen={showOverlay}>
        {showCategoriesOverlay && <CategoriesPane onClose={closeOverlay} />}
      </HeaderOverlayWithIcons>
    </>
  )
}
