import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DoneIcon, ReportsIcon } from '@toasttab/buffet-pui-icons'
import {
  Error500DesktopIllustration,
  Error500MobileIllustration
} from '@toasttab/buffet-pui-illustrations'
import {
  ErrorContainer,
  ErrorHeading,
  ErrorSubheading,
  ErrorCallToActionSection,
  ErrorText
} from '../Error'
import { t, loadStrings } from '../defaultStrings'

export interface ErrorPage500Props {
  children?: React.ReactNode
  errorText?: string
  testId?: string
}

export function ErrorPage500({
  children,
  errorText = '500: Internal server error',
  testId = 'error-page-500'
}: ErrorPage500Props) {
  loadStrings()

  return (
    <ErrorContainer>
      <div data-testid={testId}>
        <div
          className='hidden md:block visible mx-auto'
          style={{ maxWidth: 600 }}
        >
          <Error500DesktopIllustration
            accessibility='semantic'
            title={t('500-error')}
          />
        </div>
        <div
          className='block md:hidden visible mx-auto'
          style={{ maxWidth: 250 }}
        >
          <Error500MobileIllustration resizeToContainer />
        </div>
      </div>
      {!!children ? (
        children
      ) : (
        <div>
          <ErrorHeading>{t('title.oops-we-have-some-cleaning')}</ErrorHeading>
          <ErrorSubheading>
            {t('subheading.not-to-worry-our-team')}
          </ErrorSubheading>
          <ErrorSubheading>
            {t('subheading-action.please-try-reloading-the-page')}
          </ErrorSubheading>
        </div>
      )}
      <ErrorCallToActionSection spacing='flex flex-col items-center space-y-4 md:space-y-0 md:space-x-4'>
        <Button
          as='a'
          href='https://status.toasttab.com'
          variant='secondary'
          iconLeft={<ReportsIcon accessibility='decorative' />}
        >
          {t('button.toast-system-status')}
        </Button>
        <Button
          variant='primary'
          iconLeft={<DoneIcon accessibility='decorative' />}
          onClick={() => window.location.reload()}
          testId={`${testId}-reload`}
        >
          {t('button.reload')}
        </Button>
      </ErrorCallToActionSection>
      <div className='space-y-2'>
        <ErrorText>{errorText}</ErrorText>
      </div>
    </ErrorContainer>
  )
}
