import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const PageMissing404MobileIllustration = React.forwardRef(
  function PageMissing404MobileIllustration(
    props: IllustrationProps,
    ref?: React.ForwardedRef<HTMLDivElement>
  ) {
    const resizeToContainer = props.resizeToContainer
    return (
      <IllustrationWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={resizeToContainer ? '100%' : '300'}
              height={resizeToContainer ? '100%' : '180'}
              viewBox='0 0 300 180'
              fill='none'
              className='inline-block'
            >
              <g
                clipPath='url(#page-missing-404-mobile_svg__clip0_2674_898)'
                strokeWidth={3}
              >
                <path
                  d='M16.5 24.35v-14.1'
                  stroke='#E5E5E5'
                  strokeLinecap='round'
                />
                <path
                  d='M16.5 9.75c-5.6 0-10.2-4.3-10.2-9.7M16.5 9.75c5.6 0 10.2-4.3 10.2-9.7'
                  stroke='#E5E5E5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M57.6 24.35v-14.1'
                  stroke='#E5E5E5'
                  strokeLinecap='round'
                />
                <path
                  d='M57.6 9.75c-5.6 0-10.2-4.3-10.2-9.7M57.6 9.75c5.6 0 10.2-4.3 10.2-9.7'
                  stroke='#E5E5E5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path d='M77.5 44.95H0M77.5 24.35H0' stroke='#E5E5E5' />
                <path
                  d='M77.5 44.95v-20.6'
                  stroke='#E5E5E5'
                  strokeLinecap='square'
                />
                <path
                  d='M283.6 10.25v14.1'
                  stroke='#E5E5E5'
                  strokeLinecap='round'
                />
                <path
                  d='M293.8.05c0 5.3-4.6 9.7-10.2 9.7M273.4.05c0 5.3 4.6 9.7 10.2 9.7'
                  stroke='#E5E5E5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M242.5 10.25v14.1'
                  stroke='#E5E5E5'
                  strokeLinecap='round'
                />
                <path
                  d='M252.7.05c0 5.3-4.6 9.7-10.2 9.7M232.3.05c0 5.3 4.6 9.7 10.2 9.7'
                  stroke='#E5E5E5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path d='M300 44.95h-77.5M300 24.35h-77.5' stroke='#E5E5E5' />
                <path
                  d='M222.5 24.35v20.6'
                  stroke='#E5E5E5'
                  strokeLinecap='square'
                />
                <path
                  d='M126.6 165.95v-46.1M175.1 167.65v-47.8'
                  stroke='#E5E5E5'
                />
                <path
                  d='M112 178.75l38.1-30.9 39.2 30.9'
                  stroke='#E5E5E5'
                  strokeLinecap='round'
                />
                <path
                  d='M66.5 121.05v-49.7M90.8 121.05v-49.7M78.7 72.95v-16.9M92.3 72.15H65.2M223 72.95v-16.9M210.9 121.05v-49.7M235.1 121.45v-50.1M236.6 72.15h-27.2'
                  stroke='silver'
                />
                <path
                  d='M34.5 121.45c7.1 0 12.9-4.9 12.9-11v-13.3'
                  stroke='silver'
                  strokeLinecap='square'
                />
                <path d='M47.4 97.25H23.1M23.2 97.25H.1' stroke='silver' />
                <path
                  d='M266 121.45c-7.1 0-12.9-4.9-12.9-11v-13.3'
                  stroke='silver'
                  strokeLinecap='square'
                />
                <path d='M300 97.25h-47' stroke='silver' />
                <path
                  d='M111.6 106.15v15.4M188.6 105.05v16.5'
                  stroke='#FF4C00'
                />
                <path
                  d='M114.8 59.35l79.2 22.5'
                  stroke='#FF4C00'
                  strokeLinecap='round'
                />
                <path d='M168.1 23.45l-2.2 7.8' stroke='#FF4C00' />
                <path
                  d='M150.8 18.45l33 9.4'
                  stroke='#FF4C00'
                  strokeLinecap='round'
                />
                <path
                  d='M194 81.95c6.3-22.2-6.3-45.2-28.2-51.4-21.9-6.2-44.7 6.7-51 28.9'
                  stroke='#FF4C00'
                />
                <path
                  d='M111.6 105.05h77'
                  stroke='#FF4C00'
                  strokeLinecap='square'
                />
                <path d='M150 179.95v-58.9' stroke='silver' />
                <path
                  d='M300 121.45H0'
                  stroke='#FF4C00'
                  strokeLinejoin='round'
                />
              </g>
              <defs>
                <clipPath id='page-missing-404-mobile_svg__clip0_2674_898'>
                  <rect width={300} height={180} fill='#fff' />
                </clipPath>
              </defs>
            </svg>
          }
        </>
      </IllustrationWrapper>
    )
  }
)
