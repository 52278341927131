import {
  NavigationGroup,
  NavigationRail
} from '@toasttab/buffet-pui-global-navigation'

import { NavigationLinkItem } from '.'
import { useGroupsContext } from './GroupsContext'
import { MenuItem } from './MenuItem'

export interface MenuProps {
  canExpand?: boolean
  isOpen?: boolean
  onClickItem?: (item: NavigationLinkItem) => void
}

export function Menu({
  canExpand = false,
  isOpen = false,
  onClickItem = () => {}
}: MenuProps) {
  const groups = useGroupsContext()

  return (
    <NavigationRail
      aria-expanded={isOpen}
      canExpand={canExpand}
      isExpanded={isOpen}
    >
      {groups.map((group) => (
        <NavigationGroup key={`sidebar-group-${group.id}`}>
          {group.items.map((item) => (
            <MenuItem key={item.id} item={item} onClick={onClickItem} />
          ))}
        </NavigationGroup>
      ))}
    </NavigationRail>
  )
}
