import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const EmployeesIcon = React.forwardRef(function EmployeesIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M6 14.664a3.4 3.4 0 100-6.8 3.4 3.4 0 000 6.8zm0 0a5 5 0 00-4 2m4-2a5 5 0 014 2m-1.6 3.2a7.696 7.696 0 016.8-4.4m0 0a7.695 7.695 0 016.8 4.4m-6.8-4.4a5.664 5.664 0 100-11.328 5.664 5.664 0 000 11.328z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeLinecap='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
