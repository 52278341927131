import {
  ChecklistIcon,
  CredentialsIcon,
  HomeIcon,
  PayrollSetupIcon,
  RestaurantIcon,
  ViewCardIcon,
  WebhookIcon
} from '@toasttab/buffet-pui-icons'
import { BaseItem } from './type'

export const mainItems: BaseItem[] = [
  {
    label: 'Home',
    id: 'home',
    icon: HomeIcon,
    to: '/home'
  },
  {
    label: 'Credentials',
    id: 'credentials',
    icon: CredentialsIcon,
    to: '/credentials'
  },
  {
    label: 'Connected restaurants',
    id: 'connected-restaurants',
    icon: RestaurantIcon,
    to: '/connected-restaurants'
  },
  {
    label: 'Marketplace listing',
    id: 'marketplace-listing',
    icon: ViewCardIcon,
    to: '/marketplace-listing'
  },
  {
    label: 'Payment methods',
    id: 'payment-methods',
    icon: PayrollSetupIcon,
    to: '/payment-methods',
    featureFlag: 'partners-digi-spi-config'
  },
  {
    label: 'Webhooks',
    id: 'webhooks',
    icon: WebhookIcon,
    to: '/webhooks'
  },
  {
    label: 'Activity log',
    id: 'activity-log',
    icon: ChecklistIcon,
    to: '/activity-log'
  }
]
