import React from 'react'
let idCounter = 0

/**
 * THIS SHOULD ONLY BE USED IN TESTING
 * @param value number to reset to
 */
export const __resetUniqueIdCounter = (value: number = 0) => {
  idCounter = value
}

/**
 * Generates a unique ID. If prefix is given, the ID is appended to it.
 *
 * This function is NOT designed to be used inside of a hook,
 * please the useUniqueId instead because this component is not memoized
 * and will cause undesired renders
 *
 * @return the unique ID
 *
 * @example
 * const buildFakeUser = () => {
 *  return {
 *    id: uniqueId('user-)
 *  }
 * }
 *
 */
export function uniqueId(prefix: string): string {
  const id = String(idCounter++)
  return prefix ? prefix + id : id
}

/**
 * A hook to provide a memoized unique id optionally based on a prefix.
 *
 * @param id a value to be used by default, if falsy a new id will be generated using the prefix
 * @param prefix a string to be prepended to the id in cases where the id is not provided

 * @example
 * const ButtonComponent = ({ testId }) => {
 *  testId = useUniqueId(testId, 'button-')
 *  return <button data-testid={testId}>Hello!</button>
 * }
 */
export const useUniqueId = <T extends string | number = string | number>(
  id: T | null | undefined,
  prefix: string,
  // Use this if you need to enforce that a new id is created even if an id attribute is already provided
  // (note this will only really work in a meaningful way for you if you include a prefix)
  isForcedCounter?: boolean
): T =>
  React.useMemo(() => {
    if (isForcedCounter || !id) {
      return uniqueId(prefix) as any
    }
    return id
  }, [id, prefix, isForcedCounter])
