import { getCurrentEnvironment } from '@toasttab/current-environment'

const ENVIRONMENT_SUBTITLES: { [key: string]: string } = {
  dev: 'DEVELOPMENT',
  preprod: 'PREPRODUCTION',
  sandbox: 'SANDBOX',
  prod: 'PRODUCTION'
}

export const EnvironmentHeader = () => {
  const env = getCurrentEnvironment() as string
  return (
    <h1 className='type-large font-semibold ml-0 text-gray-125 flex items-center'>
      Developer Portal
      <span
        className='type-caption font-normal ml-10'
        style={{ letterSpacing: '1.2px' }}
      >
        {ENVIRONMENT_SUBTITLES[env]}
      </span>
    </h1>
  )
}
