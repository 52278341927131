import React from 'react'

type IconAccessibility = 'decorative' | 'semantic'

export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
export type IconProps = {
  className?: string
  testId?: string
  size?: IconSize
  /** @deprecated Use of title is deprecated for icons in buffet. If you need a tooltip, use the Tooltip component. If you need to provide a text equivalent to an icon, use aria-label. */
  title?: string
  /** By default, icons will render with a role of "image". Set accessibility to "decorative" if the icon adds no additional information to the element it is being used in (e.g. for buttons that have text AND an icon, the icon is likely decorative but for IconButton components, the icon will need a text equivalent). */
  accessibility?: IconAccessibility
}

export const sizes: Record<
  IconSize,
  { className: string; strokeWidth: number; displayStrokeWidth: number }
> = {
  xs: {
    className: 'h-4 w-4',
    strokeWidth: 1.5, // 1.5 * 4 / 6 = 1px
    displayStrokeWidth: 4.0 // 4 * 4 / 16 = 1px
  },
  sm: {
    className: 'h-6 w-6',
    strokeWidth: 1.5, // 1.5px
    displayStrokeWidth: 4.0 // 4 * 6 / 16 = 1.5px
  },
  md: {
    className: 'h-8 w-8',
    strokeWidth: 1.5, // 1.5 * 8 / 6 = 2px
    displayStrokeWidth: 4.0 // 4 * 8 / 16 = 2px
  },
  lg: {
    className: 'h-12 w-12',
    strokeWidth: 1.25, // 1.25 * 12 / 6 = 2.5px
    displayStrokeWidth: 3.33333333 // 3.33333333 * 12 / 16 = 2.5px
  },
  xl: {
    className: 'h-16 w-16',
    strokeWidth: 1.125, // 1.125 * 16 / 6 = 3px
    displayStrokeWidth: 3.0 // 3.0 * 16 / 16 = 3px
  },
  '2xl': {
    className: 'h-20 w-20',
    strokeWidth: 0.9, // 0.9 * 20 / 6 = 3px
    displayStrokeWidth: 2.4 // 3.0 * 20 / 16 = 3px
  }
}

export interface IconCCProps {
  iconSize: IconSize
  name: string
  copyName?: string
  icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<HTMLElement>
  >
}
