import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { track } from '@toasttab/use-heap'
import { themes } from '@local/themes'
import { App } from './app/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-tpc-depot',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],
  // sentry: {
  //   // shared with sentry main-spa
  //   publicKey: '3a3e14fe45c45059914fe36d7bd6bb25@o37442',
  //   projectId: '4505641307471872'
  // },
  sentryErrorBoundary(error) {
    track('TPC_DEPOT_LAYOUT_ERROR', { error })
    return <ErrorPage500 />
  },
  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'tpc-depot-layout-spa'
