export default {
  '403-access-forbidden': '403: Acceso prohibido',
  '404-page-not-found': '404: Página no encontrada',
  'someone-needs-to-unlock-this': 'Alguien necesita desbloquear esto para ti.',
  'please-contact-your-restaurant-administrator':
    'Por favor, contacta con el administrador de tu restaurante. Ellos saben dónde está escondida la llave de repuesto.',
  'are-you-the-administrator-please':
    '¿Eres el administrador? Por favor, contacta con <Link>Atención al Cliente</Link> para obtener ayuda.',
  'button.go-back': 'Volver',
  '404-error': 'Error 404',
  'page-missing-intro.sorry-this-dish-is-no':
    '¡Lo siento! Este plato ya no está en el menú.',
  'page-missing-subheading.we-dont-have-the-ingredients':
    'Ya no tenemos los ingredientes para hacer esto.',
  'page-missing-suggestion.maybe-try-one-of-these':
    '¿Quizás pruebes uno de estos en su lugar?',
  'link-aria-label.home': 'Inicio',
  'link-aria-label.menus': 'Menús',
  'link-aria-label.employees': 'Empleados',
  'link-aria-label.reports': 'Informes',
  'title.oops-we-have-some-cleaning':
    '¡Vaya! Tenemos que hacer algo de limpieza.',
  'subheading.not-to-worry-our-team':
    'No te preocupes. Nuestro equipo está al tanto del desorden y están ocupados lavando ollas y sartenes.',
  'subheading-action.please-try-reloading-the-page':
    'Por favor, intenta recargar la página.',
  '500-error': 'Error 500',
  'button.toast-system-status': 'Estado del sistema Toast',
  'button.reload': 'Recargar'
}
