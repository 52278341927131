import {
  MobileDivider,
  CloseButton,
  HeaderOverlay
} from '@toasttab/buffet-pui-global-navigation'
import { ReactNode } from 'react'

interface Props {
  isOpen: boolean
  onClose: Function
  children: ReactNode
}

export function HeaderOverlayWithIcons({ isOpen, onClose, children }: Props) {
  return (
    <HeaderOverlay isOpen={isOpen}>
      <div>
        <div className='flex'>
          <div className='px-2'>
            <CloseButton onClick={onClose} />
          </div>
          <div className='flex-1' />
          <div className='flex'>
            <MobileDivider variant='dark' />
          </div>
        </div>
        {children}
      </div>
    </HeaderOverlay>
  )
}
