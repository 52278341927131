import { HeaderButton } from '@toasttab/buffet-pui-global-navigation'
import { ExpandMoreIcon, ToastAccountIcon } from '@toasttab/buffet-pui-icons'
import { track } from '@toasttab/use-heap'
import { useBanquetProps } from 'banquet-runtime-modules'
import { useSelect } from 'downshift'
import { useCallback } from 'react'
import { ProfileLinkItem } from '..'

export interface ProfileDropdownProps {
  links: Array<ProfileLinkItem>
}

export function ProfileDropdown() {
  const { isOpen, getToggleButtonProps, getMenuProps } = useSelect({
    items: new Array(2)
  })

  const { auth } = useBanquetProps()

  const handleLogout = useCallback(() => {
    auth
      ?.logout()
      ?.then(() => track('TPC_DEPOT_USER_LOGOUT_SUCCESSFUL'))
      ?.catch((error) =>
        track(`TPC_DEPOT_USER_LOGOUT_FAILED`, {
          error: error as string
        })
      )
  }, [auth])

  return (
    <div>
      <HeaderButton
        {...getToggleButtonProps()}
        data-testid='profileDropdownButton'
        aria-label='open-account-menu'
      >
        <div
          aria-label='profile dropdown'
          className='flex items-center type-subhead'
        >
          <ToastAccountIcon
            className='text-secondary'
            accessibility='decorative'
          />
          <ExpandMoreIcon
            className='text-secondary'
            accessibility='decorative'
          />
        </div>
      </HeaderButton>
      <div
        {...getMenuProps()}
        className='absolute right-0 z-50 mt-2 outline-none'
      >
        <ul className='p-0 py-2 m-0 list-none bg-white rounded shadow-lg'>
          {isOpen && (
            <>
              <li
                style={{ minWidth: '15rem' }}
                className='p-3 md:py-2.5 type-small pointer-cursor hover:bg-gray-25'
              >
                <button
                  className='w-full text-left'
                  data-toast-track-id='tpc-depot-layout-user-logout'
                  onClick={handleLogout}
                >
                  Log out
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  )
}
